import React, {useState, useRef, useEffect} from 'react';

import styles from './Music.module.css'
import './Music.css'
import Album from './Album';

function Music() {


    return ( 
        <>
            <div id="glazba" className={styles.musicContainer}>
                <div className={styles.messageContainer}>
                    <p className={styles.goalText}>
                        <span className={styles.albumTitle}>
                        ALBUMI
                        </span>
                        <br />
                    </p>
                    <p className={styles.goalText}>
                        <span className={styles.albumText}>
                        predlaže se preslušati odjednom...
                        </span>
                    </p>
                </div>
                <div className={styles.textContainer}>
                    <p className={styles.goalText}>
                        <span className={styles.musicTitle}>
                        GLAZBA
                        </span>
                        <br />
                    </p>
                    <p className={styles.goalText}>
                        <span className={styles.musicText}>
                        vizualizirajte naš pogled...
                        </span>
                    </p>
                </div>
            </div>
            <Album></Album>
        </>
    );
}

export default Music;