import React, {useState, useRef, useEffect} from 'react';
import { Image, ImagePreviewType } from 'antd';

import { Collapse } from 'antd';

import styles from './Music.module.css'
import './Music.css'

import trackCetiriTrakeDefinicija from '../media/music/cetiri-trake/Farma Pasa - Definicija (Laboratorium).mp3';
import trackCetiriTrakeEkspresija from '../media/music/cetiri-trake/Farma pasa - Ekspresija (f1).mp3';
import trackCetiriTrakeTransformacija from '../media/music/cetiri-trake/Farma pasa - Transformacija (Kraj).mp3';
import trackCetiriTrakeAutocenzura from '../media/music/cetiri-trake/Farma pasa . Autocenzura (Poruka u boci).mp3';

import trackPustoparanPustoparan from '../media/music/pustoparan/Farma Pasa - Pustoparan - 01 Farma Pasa - Pustoparan.mp3';
import trackPustoparanVanSebe from '../media/music/pustoparan/Farma Pasa - Pustoparan - 02 Farma Pasa - Van sebe.mp3';
import trackPustoparanNijeLiToLudo from '../media/music/pustoparan/Farma Pasa - Pustoparan - 03 Farma Pasa - Nije li to ludo.mp3';
import trackPustoparanLandram from '../media/music/pustoparan/Farma Pasa - Pustoparan - 04 Farma Pasa - Landram (gost Eli Maca).mp3';
import trackPustoparanGorile from '../media/music/pustoparan/Farma Pasa - Pustoparan - 05 Farma Pasa - Gorile (gost. Eli Maca).mp3';
import trackPustoparanKarpeDiem from '../media/music/pustoparan/Farma Pasa - Pustoparan - 06 Farma Pasa - Karpe diem (gost. Direkt).mp3';
import trackPustoparanOpravdanja from '../media/music/pustoparan/Farma Pasa - Pustoparan - 07 Farma Pasa - Opravdanja.mp3';
import trackPustoparanOuneki from '../media/music/pustoparan/Farma Pasa - Pustoparan - 08 Farma Pasa - Ouneki.mp3';
import trackPustoparanOdmaMiDai from '../media/music/pustoparan/Farma Pasa - Pustoparan - 09 Farma Pasa - ODMA\' MI DAI.mp3';
import trackPustoparanTramak from '../media/music/pustoparan/Farma Pasa - Pustoparan - 10 Farma Pasa - Tramak.mp3';
import trackPustoparanKojaJeUra from '../media/music/pustoparan/Farma Pasa - Pustoparan - 11 Farma Pasa - Koja je ura.mp3';
import trackPustoparanMore from '../media/music/pustoparan/Farma Pasa - Pustoparan - 12 Farma Pasa - More (gost. Eli Maca).mp3';
import trackPustoparanKK from '../media/music/pustoparan/Farma Pasa - Pustoparan - 13 Farma Pasa - KK (gost. Eli Maca).mp3';
import trackPustoparanRakat from '../media/music/pustoparan/Farma Pasa - Pustoparan - 14 Farma Pasa - Rakat (+1).mp3';
import trackPustoparanIzvor from '../media/music/pustoparan/Farma Pasa - Pustoparan - 15 Farma Pasa - Izvor.mp3';
import trackPustoparanKulturaMemoara from '../media/music/pustoparan/Farma Pasa - Pustoparan - 16 Farma Pasa - Kultura memoara.mp3';
import trackPustoparanDoKraja from '../media/music/pustoparan/Farma Pasa - Pustoparan - 17 Farma Pasa - Do kraja.mp3';
import trackPustoparanPustoparanBonus from '../media/music/pustoparan/Farma Pasa - Pustoparan - 18 Farma Pasa - Pustoparan bonus (gost. Luka Nikolić).mp3';

import AudioTrack from './AudioTrack';

import razbijenaCasa1 from '../media/razbijena-casa-1.svg'
import razbijenaCasa2 from '../media/razbijena-casa-2.svg'
import razbijenaCasaTekst from '../media/razbijena-casa-txt.png'
import rijec from '../media/rijec.png'
import voda from '../media/voda.png'
import pold from '../media/POLD.png'

import pustoparan1 from '../media/pustoparan-1.png'
import pustoparan2 from '../media/pustoparan-2.png'
import pustoparanTekst from '../media/pustoparan.png'
import moreTekst from '../media/more.png'
import landramTekst from '../media/landram.png'
import odmaMiDaiTekst from '../media/odma-mi-dai.png'

import cetiritrake1 from '../media/covers/cetiri-trake/definicija.jpg'
import cetiritrake2 from '../media/covers/cetiri-trake/transformacija.jpg'

import razbijenaCasaNaslov from '../media/razbijena-casa-naslov.png'
import pustoparanNaslov from '../media/pustoparan-naslov.png'
import cetiriTrakeNaslov from '../media/cetiri-trake-naslov.png'

function Album() {
    const lyrics = [
        {
          key: 'pustoparan',
          label: 'Tekst',
          children: <p style={{margin: 0, color: 'white', textAlign: 'center'}}>
            Prepoznaj;- želju, znatiželju pravog čovjeka<br/>
            prvo sam osoba onda igrač sa pustog otoka<br/>
            trebam nove zjenice da vidim nove dimenzije<br/>
            imam novo ime, nabavit ću novo prezime<br/>
            samo da mogu napisat novi rezime<br/>
            usporenih koraka, ak' umrem u ritmu reži me<br/>
            nije mi bitno, al' nemoj krasti moje korake,<br/>
            trebam pod hitno odletjet, vidjet oblake<br/>
            ne moram reć' koliko sam puta sanjao<br/>
            da mi poslanice čita Sveti Pavao<br/>
            proširi mi zjenice, ispleti si pletenice<br/>
            vadim olovku, za novu odluku, iz pernice<br/>
            pusto mi je trebam gru, šećem, novi filing stvaram<br/>
            tetovaža pod obrazom;- suza, ja sam pustoparan<br/>
            pusti putokaz i pokaz, imam samo jedan dokaz,<br/>
            sam' okreni obraz, vidim da se vrti kompas<br/>
            opis novog lika kroz obris, otvaram novi list,<br/>
            ovaj put je tako stvaran - glinu bacam na gerist<br/>
            modeliram novu modu, gru u hodu, novi lik stvaram<br/>
            samoća novi zvuk, samotni vuk - ja sam pustoparan
          </p>,
        },
        {
            key: 'van-sebe',
            label: 'Tekst',
            children: <p style={{margin: 0, color: 'white', textAlign: 'center'}}>
                Nekad tako ravno;<br/>
                u mojoj koži nisi bio<br/>
                kao da je bilo davno,<br/>
                al' me fali dio<br/>
                gradska svjetla,<br/>
                sve ide tako sporo...<br/>
                oni viču:- „uspjeli!“<br/>
                ja im kažem:- „skoro“ <br/>
                gruba poetika, što se dešava?<br/>
                novi radni dan - mora da je takva predstava<br/>
                radim ono što ti se sviđa!<br/>
                pitaš me šta ima? – zamršena priča<br/>
                mogao bih pričat – je l' to stvarno želiš?<br/>
                to ti se sviđa? – kaj odma' ne veliš! <br/>
                kako me se sjetila? – imam otuđena osjetila<br/>
                želi probudit moju raspadljivu realnost,<br/>
                novi život kao dužnost, svaki korak je budućnost,<br/>
                trenutak kao - preteča opasnost<br/>
                mijenjam svoje pristupe – narav na rubu,<br/>
                oči stoje kao priglupe, al' viši za jednu stubu!<br/>
                <br/>
                kako me se sjetila? imam otuđena osjetila <br/>
                želi probudit moju raspadljivu realnost,<br/>
                novi život kao dužnost, svaki korak je budućnost,<br/>
                trenutak kao - preteča opasnost<br/>
                <br/>
                sve je bliže i bliže, <br/>
                pitanje napada izravno<br/>
                kako nešto krivo<br/>
                može biti tako ispravno?<br/>
                Gorčina istine <br/>
                mogućnosti su velike,<br/>
                trag ne toliko bitan,<br/>
                al' dio životne etike <br/>
                čekao sam strpljivo, spreman za napad! <br/>
                što ću sad? – pitanje nastavlja kapat<br/>
                panoramski pogled zvuči prekrasno <br/>
                novi život! – nadam se da nije prekasno <br/>
                gorčina trećeg djeteta, želim samo divno,<br/>
                al' mi ne smeta – kažu živim krivo<br/>
                ne tako dugo, gledam kako se raspada <br/>
                imam razloge - gorka balada!<br/>
                Život na rubu! – ja bih odmah sad<br/>
                snaga za rad il' snaga za umrijet mlad?<br/>
                van sebe sam...
            </p>,
        },
        {
            key: 'nije-li-to-ludo',
            label: 'Tekst',
            children: <p style={{margin: 0, color: 'white', textAlign: 'center'}}>
                Nije li čudno da mnogi tvrde da ne vjeruju u čuda?<br/>
                – dok očekuju čudo?<br/>
                skroz ćeš poludi't ako malo ne poludiš<br/>
                – nije li to ludo?<br/>
                i što zapravo znači zabranjeno voće<br/>
                ako baš svatko radi ono što hoće, <br/>
                to mi lagano izgleda poput lovčeve omče<br/>
                jer ništa nije besplatno... shvaćaš me momče?<br/>
                – previše toga, otečena memorija,<br/>
                u mojoj glavi dolazi viktorija,<br/>
                nije baš brza, al' bi mogla bit i sporija,<br/>
                ako nešto dobro slutim ulovi me euforija,<br/>
                ali znaš mene, ja sam hrabar<br/>
                nemoj se ustručavat meni dati šamar,<br/>
                samo nemoj krivo protumačiti kadar,<br/>
                jer šta znači biti čovjek šta znači biti barbar?<br/>
                <br/>
                mnogi padaju u svojim snovima, oko vrata je preteška kovina<br/>
                mnogi padaju u svojim snovima, oko vrata je preteška zlatna kovina<br/>
                <br/>
                ako živim da umrem, želim živ vidjet,<br/>
                ne želim željet, želim živjet<br/>
                povezivanje na razini, teško je reć' zbogom mol,<br/>
                vrst boli koju ne može utopit ni alkohol,<br/>
                ne spominjem jer me grebe, boje na obzoru koje lebde,<br/>
                nije li ludo da sam otišao do Trsta naći sebe?<br/>
                kao da ne znam kako bi trebalo bit',<br/>
                ovo vrijeme i mjesto za mene su pravi mit,<br/>
                kako iskoristit samostalnost, uglazbit funkcionalnost?<br/>
                praskozorje se vleče dok lovim svoju starost<br/>
                i još uvijek maštu golica moju, dok nas laži stavljaju sa strane<br/>
                i zovu me na druženje, al' anđeli mi brane<br/>
                i šarene boje koje daju život leptiru<br/>
                gube prijatelje zbog grubih riječi na papiru<br/>
                <br/>
                mnogi padaju u svojim snovima, oko vrata je preteška kovina<br/>
                mnogi padaju u svojim snovima, oko vrata je preteška zlatna kovina <br/>
                <br/>
                i nekad nema goluba da te miluje,<br/>
                na grani promišlja egzistenciju,<br/>
                al' bolje vrabac u ruci nego golub na grani,<br/>
                bolje imat' svoju nego zamišljat nečiju<br/>
                naprtnjača napregnuta – zato se gušim,<br/>
                ljudi za tuđe pogreške sami sebe krive<br/>
                nema ni jedan dovoljno dobar razlog zašto pušim<br/>
                to je pogled iz ptičje perspektive<br/>
                nekad se razbolim samo da ozdravim<br/>
                nije li ludo da učim da na kraju sve zaboravim<br/>
                jer nema zabave do dječje igre <br/>
                kaj misliš bili bi bilo na kraju vrijedno brige?<br/>
                I uteg koji nosim kroz svoj životni vijek<br/>
                teško jer se borim, a na početku puta tek<br/>
                sad smo kao na novoj razini! – budućnost lunja kao munjena,<br/>
                ali tako se osjećamo još od spilja i ugljena<br/>
                i sve stvari i situacije koje sam viđao kao muha na zidu<br/>
                i zidovi imaju uši – za sve stvari koje sam imao u vidu<br/>
                na kraju, meni je i to
            </p>,
        },
        {
            key: 'landram',
            label: 'Tekst',
            children: <p style={{margin: 0, color: 'white', textAlign: 'center'}}>
                Bio sam fokusiran dva, tri, možda četiri sata,<br/>
                di bi bio kad bi me diralo tko me čime smatra<br/>
                kad landram vučem stopala preblizu zemlji,<br/>
                kad landram mrmljam u bradu samome sebi<br/>
                uvijek u pokretu, korak koda lebdim,<br/>
                ali drmaju se ploče i tresu se temelji<br/>
                temelji znanja, temelji temelja,<br/>
                temelji vještine jer temelji su nevjera<br/>
                daleko od pogleda, također od bontona<br/>
                di objesim svoj šešir tu sam kao doma<br/>
                brojim okružja, mjesta i kvartove,<br/>
                šake i šačice usklađuju svoje satove<br/>
                ne izlazi van, kažu, vani blatno je,<br/>
                prvom čovjeku otpad, drugome je zlato<br/>
                znaš da možeš imat' sve, brate svijet je tvoj!<br/>
                čuda i čudesa, vidio sam ih na bezbroj<br/>
                daj mi krila anđela da bacim pogled iz visine<br/>
                ideje tako dobre danas, ali tako jeftine<br/>
                udri igru iz šale, lupi brigu na veselje<br/>
                nešto radim iz zabave, nešto iz znatiželje<br/>
                čisto landranje, nešto poput zanata,<br/>
                što iz sprdnje, što iz inata<br/>
                umrijet ću bez krova nad glavom, ali ispod Kamena Zaglavnog,<br/>
                svi kažu nikad nisam upoznao lika tako zabavnog<br/>
                ravno s farme, dio pseće životne etike,<br/>
                ispadaju zubi zbog izgovorene grube poetike,<br/>
                skupljam elemente svijeta za životni kolaž,<br/>
                ali ... - živimo laž<br/>
                promatram oko sebe, vrišti horror vacui,<br/>
                svaki drugi promatrač sve više sličan Drakuli,<br/>
                većinu vremena sam sam, nekad sa mnom škvadra,<br/>
                čista definicija toga kako se landra!<br/>
                <br/>
                Nitko me ne goni, ali nešto me vuče<br/>
                tijelo radi svoje, a emocije se luče<br/>
                nema nikoga u birtiji, nikoga na kavi,<br/>
                kad se najmanje ponadaš onda se pojavi<br/>
                onaj što – landra, landra - danima i noćima,<br/>
                zavoj slijepe ulice za mene crvotočina,<br/>
                daleko od crne kronike, daleko od zločina,<br/>
                duh toliko jak da ne povjeruješ očima<br/>
                ovi se kreću u čoporima, jatima,<br/>
                ja da budem malo sam čekao sam satima<br/>
                to je moje! – nađi moj urin pred svojim vratima,<br/>
                čisti balans – dva kraja ima jedna batina<br/>
                načulene uši, ali shvaćanje je plitko,<br/>
                najveći si car kada shvatiš da si nitko!<br/>
                Nađi neočekivano, kao tajnu noćnog buđenja<br/>
                landranje za ovaj svijet samo još jedna dimenzija čuđenja,<br/>
                ako staza ne ide po mojem - frende, lupim ju!<br/>
                pored mene samotni vuk izgleda ko' da ima skupinu,<br/>
                nije da tražim previše, samo slijedim sudbinu,<br/>
                ne možeš ići pravim putem ak' nemreš podnijet turbulenciju,<br/>
                izvan sustava, izvan svakog kalupa,<br/>
                naježiš se kad čuješ zvuk samotnoga galopa<br/>
                proveo noći u društvu mnoge lijepe žene,<br/>
                al' su iz nekog razloga ostale bezimene<br/>
                da dobim kunu svaki put kad mi tratiš vrijeme<br/>
                ne bih odvojio ni trenutak na to da zbrajam cijene jer<br/>
                landram! - žigolo i skitnica,<br/>
                kak' ti jako važna noć za mene samo sitnica<br/>
                lik šta je budan 168 sati tjedno,<br/>
                lik kojem zabraniš i napravit će svejedno,<br/>
                lik koji nosi istu odjeću po tjedan dana;-<br/>
                čista definicija toga kako se landra!<br/>
                <br/>
                Što ti je učiniti kada te puste s toga lanca?<br/>
                Na odlazak se spremam, nešto me u grlu steglo'!<br/>
                Dolazim ugostiti slobodu svog starog znanca! (tog starog znalca)<br/>
                Zdravo cesto, stari druže, samo još jedno svijetlo!<br/>
                <br/>
                Što ti je učiniti kada te puste s toga lanca?<br/>
                Na odlazak se spremam, nešto me u grlu steglo'!<br/>
                Dolazim ugostiti slobodu svog starog znanca! (tog starog znalca)<br/>
                Zdravo cesto, stari druže, samo još jedno svijetlo!
            </p>,
        },
        {
            key: 'gorile',
            label: 'Tekst',
            children: <p style={{margin: 0, color: 'white', textAlign: 'center'}}>
                Kaj nema više gemišta?<br/>
                ne osjećam više ništa<br/>
                gledam 360˙ zakasnio sam, reci mi - di ide bus?<br/>
                Okrenuo se previše - istegnuo sternocleidomastoideus,<br/>
                ali svi su odličnici u tandemu<br/>
                uzimam sve u obzir pa se izgubim u svemu<br/>
                i trčkaram okolo, ulovila me sreća,<br/>
                trčim okolo, a rupa na čarapi sve veća<br/>
                lice koje razabire, ruka koja rezbari,<br/>
                mali znatiželjko koji puni svoj herbarij, <br/>
                ali nije slučajno - nije ziher - nije bez veze proradio kliker,<br/>
                ne'am tu potrebu bez veze, ne želim zabadav' zalit biljke<br/>
                imun na opasnost odzvanja kao jeka, našim starim nije treba' lijeka!<br/>
                svaki čovjek, budala, svaki mudrac zna da je klijet bila apoteka!<br/>
                ne zanima me buduće, ne zanima me prošlo<br/>
                sve na svom, sve je u svoje vrijeme došlo<br/>
                u Peri mir, u Peri svira lira,<br/>
                Bašo ima napravit đir do Trogira,<br/>
                Bašo veli: – „Pero trebam potporu.",<br/>
                Pero se smije, jer Pero je na odmoru,<br/>
                ali moram reći Jopi da sam s njim u kombi<br/>
                vidli smo svijeta - napisano u Europi!<br/>
                ispod balonera cijena se važe;-<br/>
                što je tvoje moje je, što je tvoje je naše<br/>
                svi su neš' napeti, ekipa mi se stisla –<br/>
                život samo u Božjim očima ima smisla!<br/>
                <br/>
                Toliko toga na svijetu je<br/>
                vrijeme će pokazati, <br/>
                a ti samo vjeruj, <br/>
                ako možeš vjerovati!<br/>
                <br/>
                Toliko siromašni da štedimo na semplovima,<br/>
                ne bavimo se novim nego rabljenim dijelovima<br/>
                oprema istrošena, košulja iznošena,<br/>
                al' duša je još (poš, poš, poš) poštena!<br/>
                - ispadaju nova sranja iz galerije,<br/>
                nije bitno vrijeme jer štampamo na serije<br/>
                bogat i siromašan, srednja klasa istovremeno,<br/>
                na škarniclu napisan plan prije nego što krenemo<br/>
                a iz više razloga sluša i policija<br/>
                jedan od njih je jer je bit faking delicija!<br/>
                zamisli čovjeka koji je svoju tragediju<br/>
                pretvorio u jednu fantastičnu komediju<br/>
                od svakodnevnog životarenja i skupljanja para<br/>
                do vječnog đaranja u Caddyju!<br/>
                to je tvoj brat, djed, stric, otac - cijela familija!<br/>
                to je David, Samuel, Mojsije i prorok Ilija!<br/>
                možda bih trebao, možda i ne bih, <br/>
                ali situacija iz drugog očišta ne može bit milija<br/>
                od prosjaka do sinova ja sam još uvijek prosjek,<br/>
                ali brate, ja sam tvoj čovjek!<br/>
                pravi misterij od kud' je došlo sve,<br/>
                ako te strah odi u crkvu ili svetište<br/>
                čini se da samo konji i budale rade<br/>
                imamo sve što trebaš, al' ne preispituj previše!<br/>
                Toliko suza na svijetu je,<br/>
                čini se sami mrak,<br/>
                a ti samo živi,<br/>
                samo živi i budi jak!
            </p>,
        },
        {
            key: 'karpe-diem',
            label: 'Tekst',
            children: <p style={{margin: 0, color: 'white', textAlign: 'center'}}>
                Šta možeš napravit danas ne ostavljaj za sutra,<br/>
                to je odavno rečeno<br/>
                nitko ne garantira da ćeš se probudit sljedećeg jutra<br/>
                ne bih trebao zvučat opterećeno<br/>
                Ali - sutra je samo u teoriji,<br/>
                jučer je samo u mojoj memoriji,<br/>
                dok svojim lijenim mislima dajem smisao nek me nitko ne dira u privatnoj prostoriji<br/>
                fokusiran na ovdje i sada<br/>
                ja sam prestar, a ona premlada,<br/>
                ali možda se nešto desi ako odmah sada započnemo!<br/>
                ježim se pored čeličnih fasada,<br/>
                jer je tako hladno unutar grada<br/>
                vodi me dalje od okvira unutar kojih se osjećam zatočeno!<br/>
                jučer sam promišljao danas, danas ću promišljat sutra,<br/>
                izvan samog sebe, s pitanjem - kaj stvarno ima unutra?<br/>
                nekad stružem komade vlastite prošlosti sa papira izbrisanog sa lica zemlje,<br/>
                dok se ne rastopim kao na vrućoj tavi pet grama putra,<br/>
                imam tonu stvari za napravit, napravit ću ih malo sutra!<br/>
                guranje lopata u podrum vlastite podsvijesti – to može biti jedino loše za tebe,<br/>
                ali još uvijek mlad, zločinačkog uma,<br/>
                misli se rastežu kao kauguma!<br/>
                zaborav na ambiciju i višu poziciju, odbijam sva sranja za ovaj tren<br/>
                šef kaže napravi odmah, ja se nasmijem odmah, kažem;- „Ne, hvala“! - uz karpe diem!<br/>
                <br/>
                Karpe diem – kamo idem, ne znam<br/>
                znam da sam budan svjestan, znam,<br/>
                kratko traje dan, pola odlazi na san,<br/>
                ako ga prespavam manje dam na van,<br/>
                a sve je vrijeme - vrijeme je sve <br/>
                potrošio ga previše al' to mijenja se<br/>
                jasan cilj i smjer, ko' propeler,<br/>
                pa popizdim jer mi kažu smiri se – NE!<br/>
                Pa naravno da neću<br/>
                iskoristim dan, ne igram na sreću<br/>
                tijela se okreću i stoje na mjestu,<br/>
                rime mi ulijeću čim promijeniš temu<br/>
                živim u trenu, nisam u tandemu<br/>
                odradim smjenu, al' imam veću težnju<br/>
                kad izgubim želju mogu pod zemlju <br/>
                do tad' imam sve – sve u svemu!
            </p>,
        },
        {
            key: 'opravdanja',
            label: 'Tekst',
            children: <p style={{margin: 0, color: 'white', textAlign: 'center'}}>
                Ludi provod – svi pričaju trulež<br/>
                samo tvoje stanje uma čini da truneš<br/>
                pozitivne misli kažu;- „Ajde se ne brini!“<br/>
                dok imam sranja sa strane, imam sranja u sredini<br/>
                stvari koje su bile, koje su se mogle dogodit,<br/>
                koje sam izbjegao i koje nisam mogao odbit<br/>
                sve je bazirano na krađi, sa sobom u svađi,<br/>
                čini se da mixtape nikad neće ni izaći!<br/>
                vidio sam da se ovdje pod stolom dobacuju s važnim stvarima,<br/>
                a pola tog im padne na pod!<br/>
                čini se da bih cijeli dan mogao razmišljati o važnim stvarima,<br/>
                dok mi najvažnije misli stalno padaju na pod!<br/>
                strm teren frende, mogao je past bilo tko!<br/>
                istok grada frende, znaš ti uopće di je to?<br/>
                Koliko ima sati? – kažu u gradu je ljubav,<br/>
                biciklom do centra da izbjegnem sustav<br/>
                otišao sam s biciklom, bez bicikla sam se vratio<br/>
                paranoja do doma, jel' me itko pratio!?<br/>
                -paranoja do doma, jel' me itko pratio!?<br/>
                Imam se opravdavat, muče me na suho,<br/>
                kao dječarac – drže me za uho!<br/>
                <br/>
                moram odgovarat za ukradeno i posuđeno,<br/>
                također opravdat nađeno i izgubljeno<br/>
                nekad volim pažnju, nekad mi je tijesno<br/>
                suton je bio iza, šuma je bila desno<br/>
                neke stvari radim slučajno, neke stvari svjesno,<br/>
                ne guraj svoje prste tamo di im nije mjesto!<br/>
                pozdrav za šupka koji voli krast!<br/>
                na dijelu ili ne, kad tad ćeš past!<br/>
                poserem ti se na glavu, više se nećeš ni usudit<br/>
                čak niti pitat da li smiješ posudit!<br/>
                mene sad zovu, i'am prodike i sranja,<br/>
                već mi je zlo, i ispran od opravdanja!<br/>
                trčim ulice na najubojitiji tempo!<br/>
                nekad tako naporno, nekad tako lijepo!<br/>
                sad me svi zovu, pitaju me di sam<br/>
                moram pravdat ono zašto kriv nisam<br/>
                šta sam radio, pitaju me di sam bio<br/>
                već duže vrijeme fali me dio<br/>
                nisam sav svoj – možda malo bijesan,<br/>
                kažu introvertan – možda malo jesam!<br/>
                miriše na tučnjavu – nos ti nije ravan,<br/>
                i vlasti vole pitati;- „Jesi li kažnjavan?“<br/>
                objasni molim te pitanja i uputstva<br/>
                razne prodike dolaze iz gorkih usta<br/>
                - tvrde da su me znali prije nego što su moji anđeli pali... <br/>
                preduboko tražim, opipavam promjenu,<br/>
                klečuć' pred oltarom na jednom koljenu<br/>
                sad mladić što voli pričat, zanimljiv u očima mudraca,<br/>
                sad čovjek na vješalima jer to je kradljivac srdaca!<br/>
                I divlji pašnjaci bi se mogli pozlatit<br/>
                tako apstraktno nećeš moći ni shvatit;-<br/>
                Ako priznam da jesam;- nisam siguran jer ne znam,<br/>
                možda bude lažno jer nisam uvijek u pravu,<br/>
                a ako kažem da nisam;- možda krivo priznam,<br/>
                nisam siguran zato jer sam udaren u glavu!<br/>
                moram opravdat zašto je ton mojeg portreta siv<br/>
                i odgovarat za glupa sranja za koja praktički ni nisam kriv!

            </p>,
        },
        {
            key: 'ouneki',
            label: 'Tekst',
            children: <p style={{margin: 0, color: 'white', textAlign: 'center'}}>
                (Mora da je bio neki stvarno divan san!)<br/>
                <br/>
                Oni me liječe, ja sam opterećen,<br/>
                još nisam niti sazrio, kažu da sam oštećen<br/>
                produkt nepovezanosti moždanih funkcija<br/>
                samo iz razloga što me smeta prostitucija<br/>
                imam frustracije iznad govora<br/>
                rečenica od prije prelako izblijedi<br/>
                opet me ulovilo, trebam odmora!<br/>
                buntovna šutnja, reci što ti vrijedi?<br/>
                u besmislenoj vožnji do živčanog sloma<br/>
                unutar svoje kože, al' se ne osjećam kao doma<br/>
                na kazaljci omča – omča oko vrata,<br/>
                prije je išla dolje, sad ide do vrha sata<br/>
                Guši me! - trebam pero, provjeri zadnje poruke,<br/>
                Vrijeme curi! – trebam tintu – donosim važne odluke<br/>
                vrijeme me gazi, noga je na stazi<br/>
                vikao sam večeras odlazim!<br/>
                kad će opet doći? – je l' uopće prestalo?<br/>
                trzavica je došla nazad, a uzbuđenja je nestalo!<br/>
                Zagrebački igrač! – vučem se kroz kvartove,<br/>
                tenisice blatnjave – slabe, prigušene rasvjete<br/>
                bolno iskustvo, al' hvatam rukama savjete!<br/>
                Teško je doći do svog komada mira<br/>
                kad svojim putem ne upravljate!<br/>
                I nepravilnost od betona! – i vučem se po tome,<br/>
                i zaboravio sam razmišljat, ali sam razmišljao o tome!<br/>
                I nekad fakat ne ide! I kaj je s glavom frende?<br/>
                selim se kao nomad, jer me fali komad!<br/>
                volim cjelinu, al' ostavljam stvari ne dovršene<br/>
                i noge me ostavljaju jer su pre skršene...<br/>
                mračan put, nisam znao di su zapeli,<br/>
                ali ona sam shvatio da nisu jer su im put rasvijetlili anđeli!<br/>
                – nema predaje vučem se iz govana!<br/>
                nema pravednije borbe od dvoboja!<br/>
                borio sam se protiv sebe, jedan na jedan,<br/>
                i konačno sam došao do spokoja!<br/>
                <br/>
                Sad radim malo prljavo – kao da sam solo – ne'ko mi rep soli<br/>
                imam plan za izbavit nas odavde, ali nisam u kontroli!<br/>
                i laž stoji preda mnom i prekrasno je obučena<br/>
                rado bih ostao, ali lekcija mora bit naučena!<br/>
                gorim preko momenta, imam samo jednu!<br/>
                nada mi je gladna, ali sasvim sam uredu!<br/>
                kažu ne znaš što imaš dok to ne izgubiš,<br/>
                ali  nikad ne gubiš ako bezuvjetno ljubiš!<br/>
                kad sam proživljavao svaki prizor, činilo se drastično,<br/>
                a kada sam pogledao u retrovizor ispalo je fantastično, Hvala!<br/>
                <br/>
                (Stvarno divan san!)
            </p>,
        },
        {
            key: 'odma-mi-dai',
            label: 'Tekst',
            children: <p style={{margin: 0, color: 'white', textAlign: 'center'}}>
                Večeras zvoni u kvartu – na parti me nisi pozvao,<br/>
                nema beda, ne bih ni došao, na sve bih se posrao – to mi je posao <br/>
                stojim na svoje dvije, ne na tvoje dvije, kaj ima kume?<br/>
                brojimo godine, jubilare i milenijume!<br/>
                ako ti ovo zvuči hrapavo, ne'a veze, meni osobno paše hrđa<br/>
                čak i pomalo zabavno – lajat dok je lirika tvrđa,<br/>
                ali ovo su plitke riječi koje izlaze iz ljubomore!<br/>
                nabijene žlijezde, napad hormona – nabijam grube tonove!<br/>
                hoćeš li zamjerit ako netko ovaj put pretjera?<br/>
                pogam se kroz tramvaj, guram poput konduktera!<br/>
                nema dovoljno kisika u moždanim mi stanicama,<br/>
                moji ljudi se kreču prečicama, ne znaš kaj drže u ladicama!<br/>
                ODMA' MI DAI – nema dovoljno klasika – hoću sve, hoću preveć toga!<br/>
                ODMA' MI DAI – to mi je novi slogan, skladam kao Amadeus Wolfgang!<br/>
                mahnitalog li narječja - nema do bolesne ekipe<br/>
                tip osobe koji hoće sve, ali ne želi potrošit ni lipe!<br/>
                nemoj me zvat frende jer ne izlazim van<br/>
                klub, žurka, roštilj ili festival<br/>
                ako je bal nek je maskenbal,<br/>
                jer moji ljudi trče uokolo s maskama – na glavama,<br/>
                na glavama! - plus znaju di je najbolja zabava!<br/>
                juriš u poštu, ravno kroz banku,<br/>
                nekom opsovat majku!<br/>
                uzmi, zapljeni, otuđi, ukradi<br/>
                kai si vidio ikad ikoga da pošteno radi?<br/>
                ova pjesma predstavlja zanat<br/>
                olovka kojom pišem – zabodem ti je u vrat<br/>
                magla okolo, dim izlazi kroz šaht<br/>
                tko živ, tko mrtav, plan se kuje sad!<br/>
                moji slikari slikaju u noćnim satima na krovovima,<br/>
                dok mi se filter zadnje cigarete grči među zubima u bolovima!<br/>
                <br/>
                Nismo jedini koji su gladni spavali na strunjačama<br/>
                di su moja pseta s navučenim crnim kapuljačama?<br/>
                životarim s tih par sto kuna<br/>
                dok se djeca igraju u hladu na ljuljačkama<br/>
                nemoj mi dolazit s informacijama – imam ih na kilograme<br/>
                – donosimo činjenice – učenjaci vade dijagrame<br/>
                sve se okrenulo otkad je svastika naopako nacrtana<br/>
                ne miriši na sreću – bake motaju glave marama i krpama!<br/>
                ne funkcionira ni tvoja država niti cijela Zemaljska kugla<br/>
                neko je jednom birao riječi i rekao idem putem juga,<br/>
                jer Zemlja je ionako u pubertetu svojeg postojanja<br/>
                dok tučem tekst po tvrdim podlogama<br/>
                ja sam još uvijek monogaman!<br/>
                zaboravi Amere, zaboravi Engleze!<br/>
                misliš da sam sve te eseje pisao bez veze!?<br/>
                dai mi odma' dvojku u indeks,<br/>
                prije nego što nekom šamarčinu stisnem!<br/>
                pare ne račun – dai mi odma'!<br/>
                tvoje pare na mom računu – odlična komba!<br/>
                da i'am pištolj vjerojatno bi bio dizajnerski<br/>
                ne pucam iz njega nego te išamaram njime!<br/>
                ne pucam iz njega nego te išamaram njime!<br/>
                – o tome sam razmišljao dok sam pis'o ove rime!<br/>
                zakaj ne pričaš o tome kako repaš?<br/>
                zato kaj mi je neugodno pričati o tome!<br/>
                ako imaš nešto dai mi odma'<br/>
                pod to mislim donesi to ovdje!<br/>
                ako ovo ikad i k'o čuje – ODMA' MI DAI<br/>
                ako ovo čuju političari – ODMA' MI DAI<br/>
                ako i k'o ima išta – ODMA' MI DAI<br/>
                čini se da ovo sluša i tvoja stara – KAI?
            </p>,
        },
        {
            key: 'tramak',
            label: 'Tekst',
            children: <p style={{margin: 0, color: 'white', textAlign: 'center'}}>
                U birtiji smo svi jednaki – to je i tvoje,<br/>
                ali se ponašaju kao da nas se boje<br/>
                <br/>
                Gacam - ne! – bljuzgam!<br/>
                s kaputom kao plaštom<br/>
                magloviti dan je najbolji dan <br/>
                za traganje odgovora na pitanje – zašto?<br/>
                promatrajući zimu kako ledi sve simbole i znakove,<br/>
                prožimajući svoje ledene prste i krakove kroz kvartove,<br/>
                kroz radnje, stanja i zbivanja, dodiruje sve glagole,<br/>
                zaključavajte vrata i prozore jer na mirisat će kućne pragove!<br/>
                jezivo...<br/>
                odvratno!<br/>
                ništa osobno,<br/>
                ali zvuči kao dobra metafora za odnos ljudi<br/>
                mislim na komuniciranje striktno poslovno<br/>
                <br/>
                u birtiji smo svi jednaki – to je i tvoje, <br/>
                ali se ponašaju kao da nas se boje<br/>
                <br/>
                Dakle,<br/>
                ako konačno kojim slučajem uspim uhvatiti uspjeh<br/>
                hoće li se  onda pojavit neki novi uvjet?<br/>
                je l' bi rađe bio u krivu ili u samoći prolupao?<br/>
                valjda nema tog grijeha u koji se nisam slupao<br/>
                dok trijezan maštam da sanjam zaspim<br/>
                kad se probudim postajem svjestan da ponovno kasnim<br/>
                kad vidim dimnjačara primam se za dugmad na svojoj košulji <br/>
                kad se izlistaju svi gumbi na red dolaze broševi:<br/>
                zlatni, čađavi, brušeni, istrošeni <br/>
                – u birtiji smo svi jednaki, rekoh, to je i tvoje!<br/>
                bez obzira kakva je obuća obučena,<br/>
                ali se ponašaju kao da nas se boje <br/>
                i tu je granica tišine povučena...<br/>
                <br/>
                svi težu boljem i nadaju se boljem, <br/>
                ali nije ispalo puno bolje <br/>
                svi težu boljem i nadaju se boljem <br/>
                ali nije ispalo puno bolje kada smo oboje posegli za pištoljem<br/>
                <br/>
                Gacam – ne! – Gazim! – s cipelama kao oružjem!<br/>
                koliko bi trebalo vrijedit da se pomirim sa svojim raspadnutim okružjem? <br/>
                – u kvartu – koliko je sati u kvartu?<br/>
                Valjda nije prošla niti minuta otkako je neki klinac povukao lajnu!<br/>
                linija tolerancije, povučena je granica otpora<br/>
                zovi bolničare, zovi svećenika! <br/>
                – treba nam potpora!<br/>
                valjda su nas predobro poznavali<br/>
                jer o ovome su nas upozoravali!<br/>
                i o ovome se ne bi trebao šalit dečko<br/>
                jer ovo bi te moglo uplašit dečko!<br/>
                nije uvijek sve izgledalo kao privid<br/>
                ova parola, ova parada!<br/>
                pusti naš narod neka živi,<br/>
                obavio svoj posao da nitko ne strada!<br/>
                <br/>
                jer svi težu boljem i nadaju se boljem, <br/>
                ali nije ispalo puno bolje od kad smo oboje posegli za pištoljem<br/>
                u birtiji smo svi jednaki – to je i tvoje,<br/>
                ali se ponašaju kao da nas se boje!
            </p>,
        },
        {
            key: 'koja-je-ura',
            label: 'Tekst',
            children: <p style={{margin: 0, color: 'white', textAlign: 'center'}}>
                Koja je ura? – koji je sat?<br/>
                vrijeme je da moje sranje počne sjat!<br/>
                posred Velikog Medvjeda i Urse Male<br/>
                sekundice su zapele, a zvjezdice su pale!<br/>
                oplemenjena plemena nose baršunasti kroj, <br/>
                potok meda i mlijeka teče, ali nije tvoj!<br/>
                moram napravit samo tisuću toga<br/>
                ja moram napravit samo tisuću toga!<br/>
                bez tople kupke, bez MP3-a, <br/>
                bez toplog obloga i bez WiFi-a! <br/>
                žicaram bez šoldi, žniranci umjesto remena,<br/>
                al' sjenka mi je nabrekla i ugodno je odjevena<br/>
                bila je okolo, sve je vidla i sve zna<br/>
                od bježanja od Sunca do muljevitoga dna!<br/>
                od zobene kaše, Oče naš! – do mekinja, <br/>
                ne diraj papire - ovdje se drže kao svetinja!<br/>
                viđao sam kako se njima dobacuju ispod stola<br/>
                dok sam bio samo pognuta prilika muškog spola,<br/>
                al' ak sretneš malu, i ako me spomene, <br/>
                reci da možemo do mene, da lovim promjene!<br/>
                <br/>
                Možda je to moje izranjanje, lijepe mi se sinusi<br/>
                anđelčići stradavaju, a plusevi su minusi <br/>
                <br/>
                i sensei ima manu, granica može bit pomaknuta, <br/>
                također i slabost može biti ne dotaknuta,<br/>
                al' ja ih ne skrivam, ja ih izdominiram,<br/>
                nisam perfektan, ali perfekcioniram!<br/>
                buncaju li mi uši? – je l' to delirija il novi dan?<br/>
                zvuči k'o nove pare za mene jer me svrbi dlan!<br/>
                glava na glavu, pare u buntu, <br/>
                naplaćujem parking, uglavnom po funtu<br/>
                kume vjeruj mi bit će još koja tura!<br/>
                zaradi pare naša nova kultura!<br/>
                klasično sranje, al' mičemo kapitele! <br/>
                Diljem Europe naši mladi se sele<br/>
                cijelo moje jato <br/>
                leti u nepoznato...<br/>
                <br/>
                valjda bum si nekaj privredil – to su ti penez, <br/>
                nemreš živjet sa, nemreš živjet bez,<br/>
                nemreš živjet s preveć, nemreš živjet s ničim,<br/>
                a kad sam bez, na kaj ti onda ličim!?<br/>
                sve je dječja igra – pljuvanje, e basta!<br/>
                ciljam na popuste, dogovore i poznanstva,<br/>
                nešto za opustit se – zakon bratstva<br/>
                dok se cijeli život promatram i gledam kak odrastam<br/>
                <br/>
                Možda je to moje izranjanje, lijepe mi se sinusi<br/>
                anđelčići stradavaju, a plusevi su minusi!
            </p>,
        },
        {
            key: 'more',
            label: 'Tekst',
            children: <p style={{margin: 0, color: 'white', textAlign: 'center'}}>
                Zašto? - nije tako stalno?<br/>
                Tako  jednostavno <br/>
                miris dodira mora vodi do malo sjaja<br/>
                neka misli ovaj put dođu do izražaja <br/>
                držim te su svojim rukama, osjećam!<br/>
                gasim požar putem – najtežeg se i ne sjećam<br/>
                bolje nego što je bilo – namještam boje oko sebe<br/>
                pružaju se zrakama koje opuštajući lebde!<br/>
                <br/>
                Otplaćujemo svakodnevno, svakodnevno otplaćujemo cijenu<br/>
                Ovo je svakidašnja pomisao, pišemo svakodnevnu pjesmu<br/>
                Gegam se kroz prostor – dodirujem snove<br/>
                špekuliram vrijeme, ciljam na izazove!<br/>
                Oči širom otvorene kad dolaze promjene,<br/>
                Stisnem zube kroz nevolje, ove misli su popodnevne!<br/>
                kad kažem dubina onda mislim dubina,<br/>
                mislim duboko, kradem misli anđelima<br/>
                nije bitno je l' oseka, na mojoj strani je plima<br/>
                Mjesečeva mijena nema problema sa zidovima! <br/>
                Bezobvezno! – to je novi moto<br/>
                skoro sam umro jučer – dobro jutro ljepoto!<br/>
                Kreativni trenutak! – ti si moje Sunce,<br/>
                ti si moja radost i vedrina i sve šta me drži na životu!<br/>
                <br/>
                Teške riječi ne mogu mi dati ništa slično,<br/>
                traganje za srećom uvijek ispada dramatično<br/>
                i mijenjam realnost za ugodan mi privid, <br/>
                malo daljine, pa se opet ujedinit<br/>
                opusti se, po zrakama <br/>
                pružam se – potpuna predaja!
            </p>,
        },
        {
            key: 'kk',
            label: 'Tekst',
            children: <p style={{margin: 0, color: 'white', textAlign: 'center'}}>
                Sinovi zaljeva u dolini Izlaska,<br/>
                sinonim kraljeva, milina prilaska<br/>
                mjestu na prijestolju za dame i gospodu,<br/>
                žezlo u visinama – simbol za slobodu<br/>
                <br/>
                ali nismo niti toliko različiti – samo jedan stoji iznad tebe i mene<br/>
                nebo rasteže svoje rubove u zamjenu za uspomene<br/>
                gomilamo pravce na zemaljskoj sferi,<br/>
                poštovanje znalcima jer nismo licemjeri<br/>
                ako ginemo nek bude dijeleći muke,<br/>
                ak se dignemo nek bude držeć se za ruke<br/>
                <br/>
                I dok me svijet pokušava okrenut, ja se ne bojim, ja se ne bojim<br/>
                I dok me svijet pokušava okrenut, ja se ne bojim, ja se ne bojim,<br/>
                jer znam da ću se osloniti, ja se ne bojim, ja se ne bojim,<br/>
                jer znam da će me oslobodit, ja se ne bojim, ja se ne bojim!<br/>
                <br/>
                I igra slučajnosti će pokazat da smo u konačnici bili u pravu<br/>
                kad dođeš u Raj nemoj zaboravit stavit svoju aureolu na glavu<br/>
                opravdanja stvaraju laži! – ne želim vidjet niti jednu<br/>
                a svaki prezir je vražji i stvara kaos dok nas anđeli ne odvedu<br/>
                za nekog tuga, za mene čvrsta poveznica, <br/>
                uzaludna borba, sutra pozitivna posljedica!<br/>
                kraljevi i kraljice, kao odgovorni roditelji,<br/>
                blistavi od časti, hrabri u pogibelji!<br/>
                ponosni od sjaja stojimo najvjernije<br/>
                sa svake strane ekvatora, bilo južno il sjevernije!<br/>
                nikad se ne okrenuo, puhni vjetre<br/>
                stojim nerazdvojno za svu moju braću i sestre!<br/>
                <br/>
                I dok me svijet pokušava okrenut, ja se ne bojim, ja se ne bojim<br/>
                I dok me svijet pokušava okrenut, ja se ne bojim, ja se ne bojim...
            </p>,
        },
        {
            key: 'rakat',
            label: 'Tekst',
            children: <p style={{margin: 0, color: 'white', textAlign: 'center'}}>
                Hej – duga mi jezičina, <br/>
                svaka druga pjesma liči na <br/>
                besmislenu bajku s fakat usranim početkom, <br/>
                nostalgičnim raspletom i prekrasnim svršetkom<br/>
                <br/>
                Od zapaljenog krijesa do veselog plesa<br/>
                ovo je moja Alica u Zemlji Čudesa <br/>
                jedna za dušu, druga ide za um, <br/>
                nemoj zaboravit napisat koji je danas datum!<br/>
                <br/>
                Možeš li pročitat na crtama mojeg lica?<br/>
                20+ a još uvijek besparica<br/>
                al nema stajanja promatramo zbivanja<br/>
                pozdrav za familiju, pozdrav za strica!<br/>
                <br/>
                Papučarica mi je ukrala sve papuče, <br/>
                Al' kroz tamni oblak dolina sunca šapuće:- <br/>
                „Ne zbrajaj novce - zbrajaj blagoslove <br/>
                jer na brdu pored kleti rastu naranče!“<br/>
                <br/>
                Sva ta pitanja:- „Hoćeš li se ikad promijenit?“ <br/>
                znaš da te nikad nitko neće moć zamijenit! <br/>
                Danas sijedimo oko okruglog stola <br/>
                i svatko od nas ima pravo biti plemenit!<br/>
                <br/>
                Plus stoji jedno oko koje vidi sve <br/>
                i spremno je udomit sve lutajuće pse!<br/>
                Ne daj promjenama da te promjene <br/>
                dok konačno odrastaš i mijenjaš se!<br/>
                <br/>
                Za damu zime, damu jeseni i proljeća,<br/>
                ljetnog dijamanta – za vrata novog stoljeća,<br/>
                jedna za dušu, druga ide za um, <br/>
                sve karte na stol nek se džepovi zadebele!<br/>
                <br/>
                <br/>
                <br/>
                1+ još nisam stao! – <br/>
                Riječ Ocu – dečec još nije odrastao!<br/>
                ali i ćorava koka nađe svoje zrno, <br/>
                zato nema stajanja, frende, samo tvrdo!<br/>
                <br/>
                Rastopljeno vrijeme pleše tako Dalijevski!<br/>
                vozim tebra, na ključevima privjesci! <br/>
                Ova stvar ide za dušu – hoćeš li me dočekat na drugom kraju ceste?<br/>
                <br/>
                Dok se linearno kreće tik – tok – tik, <br/>
                realno sam bio prejadan lik!<br/>
                za sva moja pseta puštena s lanca –tako slobodno zalajte gdje ste!<br/>
                <br/>
                Ne vjerujem u napuštenu Hrvatsku<br/>
                za svu ekipu u dijaspori<br/>
                mora da je riječ o metafori – ovo se odnosi na svu moju braću i sestre!<br/>
                <br/>
                I nekad ti kroz uši puhne jugo, <br/>
                i sve kaj hoćeš radit je nekaj drugo<br/>
                nije sve uvijek tako bajno, mislim, sjeti se kak je sve započelo!<br/>
                <br/>
                Ali ni sve loše ne traje pre dugo,<br/>
                par dana i vičemo doviđenja tugo! <br/>
                Nema više možda, ako, neću i ne znam, nego svi jednoglasno vičemo hoćemo!<br/>
                <br/>
                sve šta smo radili bili smo totalno truli<br/>
                Bašina produkcija je bila jednaka nuli, <br/>
                svoj flo bolje da i ne spominjem zato jer je bio gori od ičeg!<br/>
                <br/>
                Sad radimo mjesta za svoje ljude, <br/>
                dajemo sve od sebe pa kaj bude bude<br/>
                ova stvar ide za dušu, sve šta počneš moraš početi od ničeg!<br/>
                <br/>
                Ako treba pusti i kap neka klizi niz kristalne obraze, <br/>
                ako želiš zbrajat pobjede, moraš množit poraze!<br/>
                svakog dana novi blagoslovi dolaze,<br/>
                a nevolje konačno odlaze!<br/>
                <br/>
                I došlo je vrijeme da ne budeš sam!
            </p>,
        },
        {
            key: 'izvor',
            label: 'Tekst',
            children: <p style={{margin: 0, color: 'white', textAlign: 'center'}}>
                Neke stvari laž su danas, istina su bile jučer,<br/>
                omotan muzikom jer zvuči obećavajuće<br/>
                <br/>
                nakon tolike stiske i bitke u mraku<br/>
                lovim dah zbog čarolije u zraku <br/>
                dugo sam sanjao, kad sam se probudio iz sna<br/>
                shvatio sam da ljubav nije pristrana<br/>
                napeti lukovi, uspravljeni stupovi<br/>
                sve se odapelo i puca jer se klimaju kukovi<br/>
                zapeo trijezan, osjećam se opijeno<br/>
                nemoj hodat bos kod klavira jer staklo je razbijeno!<br/>
                mora da je ljubav, na glavi joj stoji zlatni pehar<br/>
                iz kojeg isijava cijeli dugin spektar!<br/>
                možda sam u krivu zbog svoje moždane kemije,<br/>
                ali ide ravno kao pruga preko prerije<br/>
                u pustinji kao sužanj Babilonu!<br/>
                jesam li buntovan u krivom tonu?<br/>
                s druge strane opet onaj prizor<br/>
                optička prizma koja daje novi izvor<br/>
                moje oko ima novi domet,<br/>
                završio bih samo ako znam da će biti opet! <br/>
                <br/>
                Smrt laž je danas, istina je bila jučer<br/>
                omotan muzikom jer danas zvuči obećavajuće!<br/>
                <br/>
                Smrt laž je danas, istina je bila jučer<br/>
                omotan muzikom jer danas zvuči obećavajuće<br/>
                uperen pogled gore, unutar zvjezdane magle,<br/>
                ne dajem ti do znanja da znam zašto ti se oči cakle!<br/>
                više mi znači led nego staklo!<br/>
                moguće je željet samo jednom – kako?<br/>
                Nemoj pustit suzu, kako je biti usamljen?<br/>
                užari vatru care! – nemoj zapustit plamen!<br/>
                „Sretan što dišem!“ je danas možda preteško shvatit,<br/>
                Al' kud god pozoveš, ja ću zasigurno pratit<br/>
                ponovno živim, ponovno slinim,<br/>
                ponovno vidim i opet dimim!<br/>
                <br/>
                (to je to...)
            </p>,
        },
        {
            key: 'kultura-memoara',
            label: 'Tekst',
            children: <p style={{margin: 0, color: 'white', textAlign: 'center'}}>
                Dobro jutro novo vrijeme, hvala na novom danu!<br/>
                žao mi je što se nisam našao u tvojem starom planu...<br/>
                al nema veze, <br/>
                dobro je znat!<br/>
                <br/>
                Oda radosti, šarena slika ara<br/>
                samo maleni osvrt na kulturu memoara<br/>
                smješkanje, friško stanje svijesti<br/>
                pitam se hoće li se i romantično svitanje uplesti?<br/>
                Osamljeni likovi, udaljeni od svojeg vremena,<br/>
                Ali – tako se osjećamo još od duda i pelena!<br/>
                Prošlost se zaista mora ostaviti straga<br/>
                u utrci do moje duše između mene i vraga,<br/>
                al ne živim u strahu (hehe), iznenađenje!<br/>
                Svjetska sam osoba, trebam oslobođenje!<br/>
                Imam tucet problema, ali o tome skroman,<br/>
                di objesim šešir, frende, tu sam kao doma!<br/>
                iznenađujem spletke, zastrašujem zamke, <br/>
                svetkujem svetke jer granice su tanke!<br/>
                i tako idem... dok ne otegnem papke!<br/>
                <br/>
                Dobro jutro tanka granico, balansiram na stometarskoj visini, <br/>
                pruži mi ruke da se konačno mogu naći u tvojoj dubini, <br/>
                jer svi smo samo ljudi... dobro je to znat!<br/>
                <br/>
                Imao sam potrebu;- ne željet željet<br/>
                puno gluposti izlazi dok naprežem svoje glasnice <br/>
                – Sad imam samo jednu želju – to je željet! <br/>
                Bog će pripomoći u nevolji budalice!<br/>
                smješkanje na licu – to je ljudska osnova, <br/>
                snalazit se po ritmu života i grubih tonova!<br/>
                dobro dođe prijateljska potpora, ja sam nova osoba, <br/>
                neko' mi je pomogao nositi moj križ!<br/>
                cijela skupina ljudi koja živi u svojoj prošlosti<br/>
                ne znam di je cilj al' ako si shvatio dobrodošo' si!<br/>
                guram prste nekad di ne treba, opečem se u svojoj naglosti!<br/>
                nekad ne razmišljam kada treba, o tome ću se tući po glavi u svojoj starosti!<br/>
                više me nije strah pogledat u ogledalo - samo sam čovjek, vrvim od slabosti!<br/>
                danas sam konačno nova osoba, ne trebaju biti posebni razlozi,<br/>
                <br/>
                ali dobro jutro novi tjedne, mali osvrt na kulturu memoara<br/>
                zamotana ikebana u zavoju novih dana! <br/>
                vrijeme je da odem, da ponovno potonem, <br/>
                da ponovno izronim i da mogu pričati o tome!<br/>
                Hvala novo vrijeme! – Hvala na novom danu!<br/>
                nadam se da ću se uspjet naći u tvojem novom planu!<br/>
                dobro je to... i to je nešto!
            </p>,
        },
        {
            key: 'do-kraja',
            label: 'Tekst',
            children: <p style={{margin: 0, color: 'white', textAlign: 'center'}}>
                Sve moje momčine šta stoje vične maču<br/>
                spremne na bojišnici da pred metke skaču!<br/>
                lešinari dižu pandže u zrak, ruke im razgrapne, <br/>
                blizanci na kožnom remenu viču agape!<br/>
                Kapuljača na glavi – vikali smo svi se gonite!<br/>
                grube naravi, al' nema svjetlosti do molitve! <br/>
                Prljava jezičina – to je moja momčina! <br/>
                Država sastavljena k'o slagalica zločina!<br/>
                stojiš okamenjeno, kad si omekanio?<br/>
                Dobro znaš da smo skupa prošli pakao! <br/>
                – nalikuješ skeptiku, (ali) čvrsto uvjeren, <br/>
                oštre tematike, (al') pozitivno usmjeren!<br/>
                po zlatnom rezu svaki pogled je kadriran<br/>
                za svaki put kad se situacija zakomplicira!<br/>
                u slabosti se pokaže koliko si stvarno jak<br/>
                0-24-7 – svjež u ponedjeljak!<br/>
                bit će oprošteno onome k'o prizna, <br/>
                mi kesimo zube za pravo značenje fovizma!<br/>
                ako nas ne puste unutra srušit će se rampa, <br/>
                na nađeš otiske prstiju nego psećih šapa!<br/>
                ako nestane struje u gradu zovi Leteca, <br/>
                samo još jedan pseći dan poslije punog mjeseca!<br/>
                <br/>
                Idemo do kraja (ajmo ajmo!)<br/>
                Nisu nam ni do jaja (ni do jaja buraz)<br/>
                0-24-7 (svaki dan, cijeli dan)<br/>
                Tako sam ti svaki tjedan (svaki dan, svaki dan)<br/>
                Svi smo oko stola (pijemo, jedemo)<br/>
                Nisu nam ni do pola (ma ni trećinu)<br/>
                <br/>
                Čini se da se stol nekad ipak okreče, <br/>
                za svaku laž koja pokazat svoje zube neće! <br/>
                Nepopravljivi gubitnik je sada pobjednik,<br/>
                na crno bijeloj ploči može pobijedit samo posljednji, <br/>
                za sve ljude koji krvare za svoje pravo da krvare, <br/>
                ne padaju glave – mičemo s vidika strvinare,<br/>
                za svaki leš koji izdiše osmjehom lažnog govora, <br/>
                navodni lijek koji ilustrira ništa osim otrova, <br/>
                proširi riječ u svijetu punom govana<br/>
                moć je u ljudima ne u ustanovama<br/>
                prljav! Čađav!, glava mi prevelika, <br/>
                čiste ulice zbog sranja šta padaju s mojeg jezika, <br/>
                cijeli čopor na vrhu litice zavija u isti glas, <br/>
                i svi se slažu da ih ne rade više poput nas!<br/>
                iznenađujući život postaje nešto jednostavniji, <br/>
                a studio u kojem snimamo još miriši po Jugoslaviji<br/>
                jednostavan tekst je dobar po tvoje zdravlje<br/>
                izađem zdrav od veterinara – spreman za slavlje, <br/>
                plus isti moto – moraš ostat svjež, <br/>
                rime omotane u klupko ritma pomažu ti da sazriješ, <br/>
                netko je jednom rekao – ne treba ti odmor,<br/>
                ne mogu te obuzdat – iza mene cijeli čopor,<br/>
                jadno smo krenuli, počelo s Čretom<br/>
                jako će završit skupa s cijelim Svijetom!<br/>
                <br/>
                Idemo do kraja (do kraja puta!)<br/>
                Nisu nam ni do jaja (kuda ćemo tako!?)<br/>
                0-24-7 (opet svaki dan, cijeli dan!)<br/>
                Tako sam ti svaki tjedan (i tako, i tako, i tako...)<br/>
                Svi smo oko stola (sira i pršuta!)<br/>
                Nisu nam ni do pola (dajte mi cigaru!)<br/>
                <br/>
                Zato jer nema do tog psećeg mentaliteta<br/>
                zamotanog u sredstva poput imenica i epiteta!<br/>
                ne stoji bez veze uzrečica ne brini, <br/>
                ali pazi di staješ nije uvijek toliko ugodno u divljini!<br/>
                preskačemo ograde – ne čekamo u redovima, <br/>
                mi smo divljač koja trči okolo po brjegovima!<br/>
                jurim okomito brže od uspinjače<br/>
                to je odgovor na pitanje kako sam ti inače! <br/>
                Radimo na prljavo, ruke pozlaćene od blata,<br/>
                tko je rekao da se moramo primit novog zanata? <br/>
                Nek se frizure zapale, a tjemena zapepele<br/>
                za svaki put kad nam se mišljenja debelo dijele! <br/>
                Život te ošamari gore, pa pljusne dole<br/>
                čak i kad puše skačemo na jarbole, <br/>
                jer najtamnija noć je trenutak prije svitanja<br/>
                sve je jasno, svi na noge, nema više pitanja!<br/>
                toliki tvrdokožac, britva pukne dok brijem lice<br/>
                znam lika koji ima valjda najveće podlaktice!<br/>
                ponovno tvrdi – frede – pustio sam brkove!<br/>
                zastava zabijena na sve vrhove!<br/>
                zastave zabijene i na sve ravnice, <br/>
                plus nema do bolesne odjavne špice!<br/>
                znak „oštar pas“ stoji na ulazu na Farmi, <br/>
                srednji prst u zraku - mi smo vani!
            </p>,
        },
    ];

    return ( 
        <>
            <div className={styles.albums}>
                <div className={styles.albumContainer}>
                    <span>
                        <img style={{width: '100%'}} src={razbijenaCasaNaslov}></img>
                    </span>
                    {/* <div className={styles.musicCarousel}>
                        <Carousel>
                            <div>
                                <div style={contentStyle}>
                                    <AudioTrack id={"player-rijec"} track={trackPustoparanPustoparan} trackName={'Pustoparan'} style={contentStyle}></AudioTrack>
                                </div>
                            </div>
                            <div>
                                <AudioTrack id={"player-voda"} track={trackPustoparanVanSebe} trackName={'Van sebe'} style={contentStyle}></AudioTrack>
                            </div>
                            <div>
                                <AudioTrack id={"player-kapljica"} track={trackPustoparanNijeLiToLudo} trackName={'Nije li to ludo'} style={contentStyle}></AudioTrack>
                            </div>
                            <div>
                                <AudioTrack id={"player-pold"} track={trackPustoparanLandram} trackName={'Landram'} style={contentStyle}></AudioTrack>    
                            </div>
                            <div>
                                <AudioTrack id={"player-neovisan"} track={trackPustoparanGorile} trackName={'Gorile (gost. Eli Maca)'} style={contentStyle}></AudioTrack>
                            </div>
                            <div>
                                <AudioTrack id={"player-nitizlocinacaniheroja"} track={trackPustoparanKarpeDiem} trackName={'Karpe diem (gost. Direkt)'} style={contentStyle}></AudioTrack>
                            </div>
                            <div>
                                <AudioTrack id={"player-razbijenacasa"} track={trackPustoparanOpravdanja} trackName={'Opravdanja'} style={contentStyle}></AudioTrack>
                            </div>
                            <div>
                                <AudioTrack id={"player-pogleduproslost"} track={trackPustoparanOuneki} trackName={'Ouneki'} style={contentStyle}></AudioTrack>
                            </div>
                            <div>
                                <AudioTrack id={"player-punacasa"} track={trackPustoparanOdmaMiDai} trackName={'ODMA MI DAI'} style={contentStyle}></AudioTrack>
                            </div>
                        </Carousel>
                    </div> */}
                    {/* <AudioTrack id={"player-rijec"} track={trackPustoparanPustoparan} trackName={'Pustoparan'}></AudioTrack>
                    <AudioTrack id={"player-voda"} track={trackPustoparanVanSebe} trackName={'Van sebe'}></AudioTrack>
                    <AudioTrack id={"player-kapljica"} track={trackPustoparanNijeLiToLudo} trackName={'Nije li to ludo'}></AudioTrack>
                    <AudioTrack id={"player-pold"} track={trackPustoparanLandram} trackName={'Landram'}></AudioTrack>
                    <AudioTrack id={"player-neovisan"} track={trackPustoparanGorile} trackName={'Gorile (gost. Eli Maca)'}></AudioTrack>
                    <AudioTrack id={"player-nitizlocinacaniheroja"} track={trackPustoparanKarpeDiem} trackName={'Karpe diem (gost. Direkt)'}></AudioTrack>
                    <AudioTrack id={"player-razbijenacasa"} track={trackPustoparanOpravdanja} trackName={'Opravdanja'}></AudioTrack>
                    <AudioTrack id={"player-pogleduproslost"} track={trackPustoparanOuneki} trackName={'Ouneki'}></AudioTrack>
                    <AudioTrack id={"player-punacasa"} track={trackPustoparanOdmaMiDai} trackName={'ODMA MI DAI'}></AudioTrack> */}
                    <span style={{fontStyle: 'italic'}}>
                        Uskoro...
                    </span>
                </div>
                <div className={styles.albumLinksContainer} style={{textAlign: 'center'}}>
                    <div className={styles.albumCover}>
                        <Image
                            style={{width: '90%'}}
                            preview={{
                                mask: () => (
                                    <></>
                                )
                            }}
                            src={razbijenaCasa1}
                        />
                        <Image
                            preview={{
                                mask: () => (
                                    <></>
                                )
                            }}
                            style={{width: '90%'}}
                            src={razbijenaCasa2}
                        />
                        {/* <img style={{width: '40%'}} src={razbijenaCasa1}></img>
                        <img style={{width: '40%', paddingLeft: 50}} src={razbijenaCasa2}></img> */}
                    </div>
                    <div>
                        <img style={{width: '50%'}} src={rijec}></img>
                    </div>
                    <div>
                        <img style={{width: '50%'}} src={voda}></img>
                    </div>
                    <div>
                        <img style={{width: '40%'}} src={pold}></img>
                    </div>
                    <div>
                        <img style={{width: '50%'}} src={razbijenaCasaTekst}></img>
                    </div>
                </div>
            </div>
            <div className={styles.albums}>
                <div className={styles.albumContainer}>
                    <span>
                        <img style={{width: '100%'}} src={pustoparanNaslov}></img>
                    </span>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-pustoparan"} track={trackPustoparanPustoparan} trackName={'Pustoparan'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'pustoparan')} />
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-vansebe"} track={trackPustoparanVanSebe} trackName={'Van sebe'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'van-sebe')} />
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-nijelitoludo"} track={trackPustoparanNijeLiToLudo} trackName={'Nije li to ludo'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'nije-li-to-ludo')} />
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-landram"} track={trackPustoparanLandram} trackName={'Landram'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'landram')} />
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-gorile"} track={trackPustoparanGorile} trackName={'Gorile (gost. Eli Maca)'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'gorile')} />
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-karpediem"} track={trackPustoparanKarpeDiem} trackName={'Karpe diem (gost. Direkt)'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'karpe-diem')} />
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-opravdanja"} track={trackPustoparanOpravdanja} trackName={'Opravdanja'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'opravdanja')} />
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-ouneki"} track={trackPustoparanOuneki} trackName={'Ouneki'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'ouneki')} />
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-odmamidai"} track={trackPustoparanOdmaMiDai} trackName={'ODMA MI DAI'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'odma-mi-dai')} />
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-tramak"} track={trackPustoparanTramak} trackName={'Tramak'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'tramak')} />
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-kojajeura"} track={trackPustoparanKojaJeUra} trackName={'Koja je ura'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'koja-je-ura')} />
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-more"} track={trackPustoparanMore} trackName={'More (gost. Eli Maca)'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'more')} />
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-kk"} track={trackPustoparanKK} trackName={'KK'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'kk')} />
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-rakat"} track={trackPustoparanRakat} trackName={'Rakat (+1)'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'rakat')} />
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-izvor"} track={trackPustoparanIzvor} trackName={'Izvor'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'izvor')} />
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-kulturamemoara"} track={trackPustoparanKulturaMemoara} trackName={'Kultura memoara'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'kultura-memoara')} />
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-dokraja"} track={trackPustoparanDoKraja} trackName={'Do kraja'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'do-kraja')} />
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-pustoparanbonus"} track={trackPustoparanPustoparanBonus} trackName={'Pustoparan bonus (gost. Luka Nikolić)'}></AudioTrack>
                        <Collapse style={{color: 'white', width: '100%'}} ghost items={lyrics.filter(x => x.key === 'pustoparan-bonus')} />
                    </div>
                </div>
                <div className={styles.albumLinksContainer} style={{textAlign: 'center'}}>
                    <div className={styles.albumCover}>
                        <Image
                            preview={{
                                mask: () => (
                                    <></>
                                )
                            }}
                            style={{width: '90%'}}
                            src={pustoparan1}
                        />
                        <Image
                            preview={{
                                mask: () => (
                                    <></>
                                )
                            }}
                            style={{width: '90%'}}
                            src={pustoparan2}
                        />
                        {/* <img style={{width: '40%'}} src={pustoparan1}></img>
                        <img style={{width: '40%', paddingLeft: 50}} src={pustoparan2}></img> */}
                    </div>
                    <div>
                        <a target="_blank" href="https://www.youtube.com/watch?v=9fsVNih8-iM&list=PLt9fDe8XKDDIb07CcQn4mt3nTnuZZJ6Vy&ab_channel=FarmaPasa">
                            <img style={{width: '50%', paddingBottom: 40}} src={pustoparanTekst}></img>
                        </a>
                    </div>
                    <div>
                        <a target="_blank" href="https://www.youtube.com/watch?v=Vts0WR5uopA&ab_channel=FarmaPasa">
                            <img style={{width: '50%', paddingBottom: 40}} src={landramTekst}></img>
                        </a>
                    </div>
                    <div>
                        <a target="_blank" href="https://www.youtube.com/watch?v=ucNQT8ZjSy8&ab_channel=FarmaPasa">
                            <img style={{width: '50%', paddingBottom: 40}} src={odmaMiDaiTekst}></img>
                        </a>
                    </div>
                    <div>
                        <a target="_blank" href="https://www.youtube.com/watch?v=_CIgrJBWI4I&ab_channel=FarmaPasa">
                        <img style={{width: '50%'}} src={moreTekst}></img>
                        </a>
                    </div>
                </div>
            </div>
            <div className={styles.albums}>
                <div className={styles.albumContainer}>
                    <span>
                        <img style={{width: '100%'}} src={cetiriTrakeNaslov}></img>
                    </span>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-definicija"} track={trackCetiriTrakeDefinicija} trackName={'Definicija (laboratorium)'}></AudioTrack>
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-autocenzura"} track={trackCetiriTrakeAutocenzura} trackName={'Autocenzura (porukauboci)'}></AudioTrack>
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-ekspresija"} track={trackCetiriTrakeEkspresija} trackName={'Ekspresija (f1)'}></AudioTrack>
                    </div>
                    <div style={{width: '100%'}}>
                        <AudioTrack id={"player-transformacija"} track={trackCetiriTrakeTransformacija} trackName={'Transformacija (kraj)'}></AudioTrack>
                    </div>
                </div>
                <div className={styles.albumLinksContainer} style={{textAlign: 'center'}}>
                    <div className={styles.albumCover}>
                        <Image
                            preview={{
                                mask: () => (
                                    <></>
                                )
                            }}
                            style={{width: '90%'}}
                            src={cetiritrake1}
                        />
                        <Image
                            preview={{
                                mask: () => (
                                    <></>
                                )
                            }}
                            style={{width: '90%'}}
                            src={cetiritrake2}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Album;