import React, { useState }  from 'react';

import "./App.css";
import Header from "./layout/Header";
import Music from "./layout/Music";
import Contact from './layout/Contact';
import About from './layout/About';

import { slide as Menu } from 'react-burger-menu'

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleStateChange = (state) => {
    setIsMenuOpen(state.isOpen);
  };

  return (
    <div id="outer-container">
      <Menu isOpen={isMenuOpen} onStateChange={handleStateChange} className="mobile-nav-container" reveal right pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }>
          <a id="home" onClick={() => handleCloseMenu()} className="menu-item" href="#">FARMA PASA</a>
          <a id="gallery" onClick={() => handleCloseMenu()} className="menu-item" href="#glazba">GLAZBA</a>
          <a id="other" onClick={() => handleCloseMenu()} className="menu-item" href="#onama">O NAMA</a>
          <a id="contact" onClick={() => handleCloseMenu()} className="menu-item" href="#kontakt">KONTAKT</a>
      </Menu>
      <div id="page-wrap" className="App">
        <Header />
        <Music />
        <About />
        <Contact />
      </div>
    </div>
  );
}

export default App;
