import React, {useState, useRef, useEffect} from 'react';

import styles from './AudioTrack.module.css'
import './AudioTrack.css'

function AudioTrack({id, track, trackName}) {
    const playerRef = useRef(null); // Using ref to access the audio element
    const progressSegmentRef = useRef(null); // Ref for the progress segment
    const progressBarRef = useRef(null); // Ref for the progress bar for click events
    const [audioPlayed, setAudioPlayed] = useState(false);
    const [currentTimeDisplay, setCurrentTimeDisplay] = useState('00:00:00');

    const playAudio = () => {
        document.getElementById(id).play();
        setAudioPlayed(true);
    }

    const pauseAudio = () => {
        document.getElementById(id).pause();
        setAudioPlayed(false);
    }

    // Function to format time from seconds to MM:SS format
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        const hours = Math.floor(minutes / 60)
        const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        return formattedTime;
    };

    // Update progress bar
    const updateProgress = () => {
        const player = playerRef.current;
        const duration = player.duration;
        const currentTime = player.currentTime;
        const progress = (currentTime / duration) * 100;
        if (progressSegmentRef.current) {
            progressSegmentRef.current.style.right = `${100 - progress}%`;
        }

        // Update current time display
        setCurrentTimeDisplay(formatTime(duration - currentTime));
    };

    useEffect(() => {
        const player = playerRef.current;
        if (player) {
            player.addEventListener('timeupdate', updateProgress);
            player.addEventListener('loadedmetadata', function() {
                setCurrentTimeDisplay(formatTime(player.duration - player.currentTime));
            });
        }

        return () => {
            if (player) {
                player.removeEventListener('timeupdate', updateProgress);
            }
        };
    }, []);

    const onProgressBarClick = (e) => {
        const progressBar = progressBarRef.current;
        const clickX = e.nativeEvent.offsetX;
        const totalWidth = progressBar.clientWidth;
        const clickRatio = clickX / totalWidth;
        const newTime = clickRatio * playerRef.current.duration;
        playerRef.current.currentTime = newTime;
    };

    return ( 
        <>
            <audio src={track} id={id} ref={playerRef} preload='none'></audio>  
            <div class="audio-player">
                <div class="track-info">
                    <span class="title">{trackName} - {currentTimeDisplay}</span>
                </div>
                <div class="progress-container">
                    {!audioPlayed && (
                        <button onClick={() => playAudio()} class="play-btn">
                            <div class="icon"></div>
                        </button>
                    )}
                    {audioPlayed && (
                        <button onClick={() => pauseAudio()} class="pause-btn">
                            <span class="bar"></span>
                            <span class="bar"></span>
                        </button>  
                    )}
                    <div class="progress-bar" onClick={onProgressBarClick} ref={progressBarRef}>
                        <hr></hr>
                        <span class="progress-segment filled" ref={progressSegmentRef} style={{right: '100%'}}></span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AudioTrack;