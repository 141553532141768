import React from 'react';

import styles from './About.module.css'
import './Music.css'

import kosi from '../media/pero.svg'
import basic from '../media/baso.png'

function About() {
    return ( 
        <>
            <div id="onama" className={styles.aboutUsContainer}>
                <span className={styles.aboutUsTitle}>O NAMA</span>
                <span className={styles.aboutUsDescription}>ponešto o autorima...</span>
            </div>
            <div className={styles.aboutUsPeople}>
                <div className={styles.aboutUsPeopleContainer}>
                    <img src={basic}></img>
                    <span>
                        <span className={styles.fontBold}>FILIP BAŠIĆ</span> JE EKSPERIMENTALNI
                            GLAZBENI DUO KOJEG SU UTEMELJILI
                            FILIP BAŠIĆ (INSTRUMENTALIST) I
                            PETAR KOŠI (VOKALIST I LIRICIST) U
                            ZAGREBU 2014. GODINE. DO SADA
                            SMO OSTVARILI DVA SAMOSTALNO
                            OBJAVLJENA LP-A... OSJEĆAJTE SE
                            SPOZVANIMA POSLUŠATI...
                    </span>
                </div>
                <div className={styles.aboutUsPeopleContainer}>
                    <img src={kosi}></img>
                    <span>
                        <span style={{fontWeight: 'bold'}}>PETAR KOŠI</span> JE EKSPERIMENTALNI
                            GLAZBENI DUO KOJEG SU UTEMELJILI
                            FILIP BAŠIĆ (INSTRUMENTALIST) I
                            PETAR KOŠI (VOKALIST I LIRICIST) U
                            ZAGREBU 2014. GODINE. DO SADA
                            SMO OSTVARILI DVA SAMOSTALNO
                            OBJAVLJENA LP-A... OSJEĆAJTE SE
                            SPOZVANIMA POSLUŠATI...
                    </span>
                </div>
            </div>
        </>
    );
}

export default About;