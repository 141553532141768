import React from "react";

import logo from '../media/fp-logo.svg'
import logo2 from '../media/fantprod-logo.svg'
import logo3 from '../media/farma-pasa-danima-i-nocima.png'
import landing from '../media/cover.png'
import landing2 from '../media/fp-logo-font.svg'
import styles from './Header.module.css'
import './Header.css'

import { slide as Menu } from 'react-burger-menu'

function Header() {
    const goToElement = (id) => {
        const elem = document.getElementById(id); 
        window.scrollTo({
          top:elem.offsetTop,
          behavior:"smooth"
      });
    };

    return ( 
    <div>
        <div className={styles.headerContainer}>
            <div className={styles.logoContainer}>
                <img src={logo} />
                <img src={logo2} />
                <p>FARMA PASA</p>
            </div>
            <div className={styles.navigationContainer}>
                {/* <button>FARMA PASA</button> */}
                <button onClick={() => goToElement("glazba")}>GLAZBA</button>
                <button onClick={() => goToElement("onama")}>O NAMA</button>
                <button onClick={() => goToElement("kontakt")}>KONTAKT</button>
            </div>
        </div>
        <div className={styles.landingContainer}>
            <div className={styles.landingImgContainer}>
                {/* <p className={styles.landingImgText}>Sportska udruga za djecu i mlade</p> */}
                <img src={landing} className={styles.landingImg}/>
                <img src={logo3} className={styles.landingImgText}/>
            </div>
        </div>
        <div style={{textAlign: 'center', color: 'white', width: '100%', display: 'flex', justifyContent: 'center', marginTop: '50px'}}>
            <span className={styles.landingImgDescription} style={{width: '70%'}}>
                FARMA PASA JE EKSPERIMENTALNI GLAZBENI DUO KOJEG SU UTEMELJILI FILIP
                BAŠIĆ (INSTRUMENTALIST) I PETAR KOŠI (VOKALIST I LIRICIST) U ZAGREBU 2014.
                GODINE. DO SADA SMO OSTVARILI DVA SAMOSTALNO OBJAVLJENA LP-A...
                OSJEĆAJTE SE POZVANIMA POSLUŠATI...
            </span>
        </div>
    </div>);
}

export default Header;