import styles from "./Contact.module.css";
import React from "react";

import logo from '../media/fp-logo.svg'
import logo2 from '../media/fantprod-logo.svg'

import facebook from '../media/facebook.svg';
import youtube from '../media/youtube.svg';
import bandcamp from '../media/bandcamp.svg';

function Contact() {
  return (
    <div id="kontakt" className={styles.contactContainer}>
      <div className={styles.title}>
        <p>Kontakt</p>
      </div>
      <div className={styles.subtitle}>
        <p>ako Vam se sviđa naša glazba i želite to podijeliti s nama, slobodno nam dajte do znanja...</p>
      </div>
      <div className={styles.email}>
      <p><a style={{textDecoration: "none", color: "white"}} href="mailto:orkestar.pasa@gmail.com">orkestar.pasa@gmail.com</a></p>
      </div>
      <div className={styles.title2}>
        <p>OSTANITE PRIPRAVNI</p>
      </div>
      <div className={styles.subtitle2}>
        <p>popratite nas na društvenim mrežama...</p>
      </div>
      <div className={styles.logoContainer}>
        <a target="_blank" href="https://www.facebook.com/FarmaPasa/?locale=hr_HR"><img src={facebook} style={{width: '50px'}} /></a>
        <a target="_blank" href="https://www.youtube.com/@FarmaPasa/featured"><img src={youtube} style={{width: '50px', paddingLeft: 20}} /></a>
        <a target="_blank" href="https://farmapasa.bandcamp.com/album/pustoparan"><img src={bandcamp} style={{width: '50px', paddingLeft: 20}} /></a>
      </div>
      <div className={styles.logoContainer}>
        <img src={logo} style={{width: '20%'}} />
        <img src={logo2} style={{width: '10%'}} />
      </div>
      <div className={styles.footer}>
        <p>FARMA PASA © sva prava pridržana</p>
      </div>
    </div>
  );
}

export default Contact;
